import { useAwait } from '@dev-plus-plus/react-await'
import { Stack } from '@illuvium/illuvium-design'
import React from 'react'
import { useRecoilState } from 'recoil'
import { CardNoClaim } from 'src/components/card-no-claim'
import { CardNotConnected } from 'src/components/card-not-connected'
import { Container } from 'src/components/container'
import { TransitionEffect } from 'src/components/transition-effect'
import { useWalletConnector } from 'src/hooks/useWalletConnector'
import * as Page from 'src/pages/home'
import { userDataAtom } from 'src/recoil/user-data'
import { getUserData } from 'src/services/upgrade-service'
import { SILV_MINT_ID } from 'src/utils/constants'
import { getContract } from 'src/utils/contracts'

export const Home: React.FC = () => {
  const { isConnected, web3Context, signer } = useWalletConnector()
  const { account } = web3Context

  const { controller } = useAwait('populate@Home')

  const [userData, setUserData] = useRecoilState(userDataAtom)
  const [hasAlreadyClaimed, setHasAlreadyClaimed] = React.useState(undefined)
  const [isActive, setIsActive] = React.useState(false)

  React.useEffect(() => {
    if (!account || !signer) {
      return
    }

    const parseRawData = async () => {
      try {
        const userData = await getUserData(account)
        if (userData) {
          const Contract = getContract(SILV_MINT_ID, signer)
          const hasAlreadyClaimed = await Contract.claimed(userData.index)
          setHasAlreadyClaimed(hasAlreadyClaimed)
          setUserData(userData)
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsActive(true)
      }
    }

    if (!userData) {
      controller.run(parseRawData)
    }
  }, [account, controller, setUserData, signer, userData])

  return (
    <Container>
      <Stack spacing={8}>
        <Page.HomeIntro />

        <TransitionEffect
          isActive={isConnected && isActive}
          activeView={
            <TransitionEffect
              isActive={!hasAlreadyClaimed && !!userData}
              activeView={<Page.HomeContext />}
              inactiveView={<CardNoClaim minH={60} />}
            />
          }
          inactiveView={<CardNotConnected />}
        />
      </Stack>
    </Container>
  )
}
