import { BigNumber } from 'ethers'

import sILVBalances from './silv-balances.json'

const sILVBalancesParsed = JSON.parse(JSON.stringify(sILVBalances))
const formattedSILVArray = Object.keys(sILVBalancesParsed).map(
  (key: string) => ({
    account: key,
    balance: BigNumber.from(sILVBalancesParsed[key]).add('100000000000000000'),
  })
)

export const treeData = [...formattedSILVArray]
