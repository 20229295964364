import React from 'react'
import { toast } from 'react-hot-toast'
import { WalletNotification } from 'src/components/wallet-notification'
import { useTransactionStorage } from 'src/hooks/useTransactionStorage'
import { treeData } from 'src/merkle-proofs/raw-data'
import SILVBalanceTree from 'src/merkle-proofs/silv-balance-tree'
import { TransactionStatus } from 'src/recoil/transactions'
import { SILV_MINT_ID } from 'src/utils/constants'
import { getContract } from 'src/utils/contracts'

import { useWalletConnector } from './useWalletConnector'

export function useClaimSilv() {
  const { web3Context, signer } = useWalletConnector()
  const { account } = web3Context

  const { storeTransaction } = useTransactionStorage()

  const pools = React.useMemo(() => {
    if (!signer) return

    return {
      [SILV_MINT_ID]: getContract(SILV_MINT_ID, signer),
    }
  }, [signer])

  const cacheTx = React.useCallback(
    (
      hash: string,
      status: TransactionStatus,
      label: string,
      timestamp: number
    ) => storeTransaction({ type: 'claim', status, hash, label, timestamp }),
    [storeTransaction]
  )

  const claimSilv = React.useCallback(
    async (userData) => {
      if (!signer || !pools || !account) return

      try {
        const tree = new SILVBalanceTree(treeData)

        if (userData) {
          const proof = tree.getProof(
            userData.index,
            userData.account,
            userData.balance
          )
          const silvPoolV2 = pools[SILV_MINT_ID]

          const executeMigration = await silvPoolV2.claim(
            proof,
            userData.index,
            userData.balance
          )

          cacheTx(
            executeMigration.hash,
            'pending',
            `Claiming sILV2`,
            Number(new Date())
          )

          toast.loading(
            <WalletNotification
              title={'Claiming sILV2'}
              txHash={executeMigration.hash}
            />
          )

          await executeMigration.wait()

          toast.success(
            <WalletNotification
              title={'Claiming sILV2'}
              txHash={executeMigration.hash}
              message={'Your sILV2 has been successfully claimed'}
            />
          )

          cacheTx(
            executeMigration.hash,
            'success',
            `Claim sILV2`,
            Number(new Date())
          )
        }
      } catch (e: any) {
        toast.error(`Error: ${e.reason ? e.reason : 'Something went wrong'}`)
        throw Error(e)
      }
    },
    [account, cacheTx, pools, signer]
  )

  return { claimSilv }
}
