import { Footer } from '@illuvium/illuvium-design'
import React from 'react'
import { FooterMobile } from 'src/components/footer-mobile'
import { Navbar } from 'src/components/navbar'
import { useResponsive } from 'src/hooks/useResponsive'

import { Main } from './styles'

export const Layout: React.FC = (props) => {
  const { children } = props
  const { isTabletOrMobile } = useResponsive()

  return (
    <>
      <Navbar />

      <Main>{children}</Main>

      <Footer activeItem="staking" />

      {isTabletOrMobile && <FooterMobile />}
    </>
  )
}
