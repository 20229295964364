import { Contract } from '@ethersproject/contracts'
import { JsonRpcSigner } from '@ethersproject/providers'
import ERC20Abi from 'src/abi/ERC20.json'
import Silv2MintAbi from 'src/abi/Silv2Mint.json'
import { SILV_ID, SILV_MINT_ID } from 'src/utils/constants'

export const abiMapper = {
  [SILV_MINT_ID]: Silv2MintAbi,
  [SILV_ID]: ERC20Abi,
}

export const getContract = (contractId: string, signer: JsonRpcSigner) => {
  return new Contract(contractId, abiMapper[contractId], signer)
}
