import { AwaitConsumer, useAwait } from '@dev-plus-plus/react-await'
import { Box, HStack, Stack, VStack } from '@illuvium/illuvium-design'
import { ethers } from 'ethers'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { Button } from 'src/components/button'
import { Card } from 'src/components/card'
import { SmallSpinner } from 'src/components/small-spinner'
import { Text } from 'src/components/text'
import { useClaimSilv } from 'src/hooks/useClaimSilv'
import { userDataAtom } from 'src/recoil/user-data'
import { SILV_ID } from 'src/utils/constants'
import { formatAmount } from 'src/utils/helpers'

declare global {
  interface Window {
    ethereum: any
  }
}

const { formatEther } = ethers.utils

export const HomeContext: React.FC = () => {
  const userData = useRecoilValue(userDataAtom)

  const [hasClaimed, setHasClaimed] = React.useState(false)
  const [hasAddedToken, setHasAddedToken] = React.useState(false)

  const { controller, loader } = useAwait('claim@HomeContext')
  const { claimSilv } = useClaimSilv()

  const claim = async () => {
    try {
      await claimSilv(userData)
      setHasClaimed(true)
    } catch (error) {
      console.log(error)
    }
  }

  const addToken = async () => {
    try {
      const wasTokenAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: SILV_ID,
            symbol: 'sILV2',
            decimals: 18,
            image:
              'https://d2u74aihr31490.cloudfront.net/img/silv-ticker-logo.png',
          },
        },
      })
      if (wasTokenAdded) {
        setHasAddedToken(true)
      }
    } catch (error) {
      console.log(error)
      setHasAddedToken(false)
    }
  }

  if (!userData) {
    return <></>
  }

  return (
    <VStack>
      <Card w={'full'} maxW={90}>
        <Stack spacing={6}>
          <HStack>
            <Box as={'img'} src={'./images/icons/silv.svg'} alt={'silv'} />

            <Text fontWeight={'bold'}>sILV2</Text>
          </HStack>

          <Stack spacing={0} flex={1} minH={20}>
            {!hasClaimed && (
              <>
                <Text
                  lineHeight={'10px'}
                  fontSize={3.5}
                  color={'lightBlue.main'}
                >
                  Available to claim:
                </Text>

                <Box fontSize={6} mt={2} fontWeight={'bold'}>
                  {formatAmount(Number(formatEther(userData.balance)))} sILV2
                </Box>

                <Text
                  lineHeight={'10px'}
                  pt={2}
                  fontSize={3}
                  color={'lightBlue.main'}
                >
                  Includes 0.1 sILV2 to cover gas fees
                </Text>
              </>
            )}

            {hasClaimed && (
              <>
                <Text fontSize={4}>
                  Your sILV2 tokens have been successfully claimed and will now
                  appear in your wallet.
                </Text>
              </>
            )}
          </Stack>

          {!hasClaimed && (
            <AwaitConsumer loader={loader} loadingView={<SmallSpinner />}>
              <Button
                w={'full'}
                onClick={() => controller.run(claim)}
                label={'Claim'}
                isHolo={true}
                disabled={hasClaimed}
              />
            </AwaitConsumer>
          )}

          {hasClaimed && (
            <AwaitConsumer loader={loader} loadingView={<SmallSpinner />}>
              <Button
                w={'full'}
                onClick={() => controller.run(addToken)}
                label={'Add Token to Wallet'}
                isHolo={true}
                disabled={hasAddedToken}
              />
            </AwaitConsumer>
          )}
        </Stack>
      </Card>
    </VStack>
  )
}
