import { Box, VStack } from '@illuvium/illuvium-design'
import React from 'react'
import { Card, CardProps } from 'src/components/card'

export interface CardNoClaimProps extends CardProps {}

export const CardNoClaim: React.FC<CardNoClaimProps> = (props) => {
  const { ...rest } = props

  return (
    <Card
      px={{ base: 6, md: 12 }}
      py={{ base: 6, md: 12 }}
      alignSelf={'center'}
      {...rest}
    >
      <VStack spacing={4} mt={8}>
        <Box fontSize={5} fontWeight={'bold'} textAlign={'center'}>
          Looks like you have no sILV2 to claim
        </Box>

        <Box fontSize={3.5} textAlign={'center'}>
          You have either claimed your sILV2 or you’re not eligible to claim.
        </Box>
      </VStack>
    </Card>
  )
}
