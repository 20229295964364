import { Box, VStack } from '@illuvium/illuvium-design'
import React from 'react'
import { ButtonWallet } from 'src/components/button-wallet'
import { Card, CardProps } from 'src/components/card'

export interface CardRewardsConnectProps extends CardProps {}

export const CardNotConnected: React.FC<CardRewardsConnectProps> = (props) => {
  const { ...rest } = props

  return (
    <Card
      px={{ base: 6, md: 12 }}
      py={{ base: 6, md: 12 }}
      alignSelf={'center'}
      {...rest}
    >
      <VStack spacing={8}>
        <Box fontSize={5} fontWeight={'bold'} textAlign={'center'}>
          Looks like your wallet is not connected
        </Box>

        <Box fontSize={3.5} textAlign={'center'}>
          Connect your wallet to check if you're eligible to claim sILV2
        </Box>

        <ButtonWallet />
      </VStack>
    </Card>
  )
}
